<template>
  <v-card>
    <v-card-title class="">
      <span class="me-3">({{ prospects.length }}) Prospects</span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="prospects"
      item-key="id"
      class="table-rounded"
      :items-per-page="10"
      disable-sort
      :search="search"
    >
      <!-- name -->
      <template #[`item.prospect_name`]="{item}">
        <div class="d-flex flex-column" style="cursor: pointer">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.prospect_name }}</span>
        </div>
      </template>
      <template #[`item.get_date_str`]="{item}">
        {{ item.get_date_str }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'

export default {
  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Withdrawal: 'primary',
      Deposit: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      switch1: true,
      headers: [
        { text: 'Name', value: 'prospect_name' },
        { text: 'Profession', value: 'profession' },
        { text: 'Phone Number', value: 'phone_number' },
        { text: 'Email', value: 'email' },
        { text: 'Location', value: 'location' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      prospects: [],
      statusColor,
      search: '',

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      this.getProspectsDetails();
      document.title = "Manager: Savings | List of Prospects"
  },

  methods:{

    getProspectsDetails(){
      const list_id = this.$route.params.id  
      axios
        .get('/api/v1/manager/get/prospects_list/'+list_id+'/details/')
        .then(response => {
          this.prospects = response.data  // get the data and fill into campaigns
          console.log(this.prospects)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },
  },
}
</script>
